import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Instagram, MessageSquare, ArrowRight, Calendar, Heart, ListTodo, ChevronDown, Brain, Clock, Users, Layers, ChevronLeft, ChevronRight } from "lucide-react"

// Assuming Logo is correctly imported or defined elsewhere
import { Logo } from './components/logo'

interface CarouselProps {
  images: string[]
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselRef = useRef<HTMLDivElement>(null)

  const scrollToImage = (index: number) => {
    if (carouselRef.current) {
      const scrollLeft = index * carouselRef.current.offsetWidth
      carouselRef.current.scrollTo({ left: scrollLeft, behavior: 'smooth' })
    }
    setCurrentIndex(index)
  }

  const nextImage = () => {
    if (currentIndex < images.length - 1) {
      scrollToImage(currentIndex + 1)
    }
  }

  const prevImage = () => {
    if (currentIndex > 0) {
      scrollToImage(currentIndex - 1)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (carouselRef.current) {
        const scrollLeft = currentIndex * carouselRef.current.offsetWidth
        carouselRef.current.scrollTo({ left: scrollLeft })
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [currentIndex])

  return (
    <div className="relative w-full max-w-[75%] mx-auto rounded-lg p-10">
      <div 
        ref={carouselRef}
        className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide"
        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
      >
        {images.map((src, index) => (
          <div key={index} className="w-full flex-shrink-0 snap-center" style={{ height: '75vh' }}>
            <img
              src={src}
              alt={`Carousel image ${index + 1}`}
              className="w-full h-full object-contain"
              loading="lazy"
            />
          </div>
        ))}
      </div>
      <button
        onClick={prevImage}
        className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
        aria-label="Previous image"
      >
        <ChevronLeft className="w-6 h-6" />
      </button>
      <button
        onClick={nextImage}
        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
        aria-label="Next image"
      >
        <ChevronRight className="w-6 h-6" />
      </button>
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => scrollToImage(index)}
            className={`w-3 h-3 rounded-full ${
              index === currentIndex ? 'bg-white' : 'bg-gray-400'
            }`}
            aria-label={`Go to image ${index + 1}`}
          />
        ))}
      </div>
    </div>
  )
}

const Contact = () => {
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle')

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault()
    setStatus('loading')

    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      })

      if (response.ok) {
        setStatus('success')
        setEmail('')
      } else {
        setStatus('error')
      }
    } catch (error) {
      setStatus('error')
    }
  }, [email])

  return (
    {/*<section id="contact" className="py-16 md:py-24">
      <div className="container px-4 md:px-6">
        <div className="flex flex-col items-center space-y-4 text-center">
          <div className="space-y-2">
             <h2 className="text-3xl font-bold tracking-tighter md:text-4xl text-[#F3F8F1]">Join Our Waitlist</h2>
            <p className="max-w-[600px] text-[#DAEFB3] md:text-xl">
              Be among the first to experience the future of personal organization.
            </p>
          </div>
          <div className="w-full max-w-sm space-y-2">
            <form onSubmit={handleSubmit} className="flex space-x-2">
              <input 
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="flex h-10 w-full rounded-md border border-[#EA9E8D]/20 bg-[#1C2826] px-3 py-2 text-sm text-[#F3F8F1] placeholder:text-[#DAEFB3]/50 focus:outline-none focus:ring-2 focus:ring-[#EA9E8D] focus:ring-offset-2 focus:ring-offset-[#1C2826]"
                required
              />
              <button 
                type="submit" 
                disabled={status === 'loading'}
                className="inline-flex h-10 items-center justify-center rounded-md bg-[#D64550] px-4 text-sm font-medium text-[#F3F8F1] hover:bg-[#D64550]/90 focus:outline-none focus:ring-2 focus:ring-[#D64550] focus:ring-offset-2 focus:ring-offset-[#1C2826] disabled:opacity-50"
              >
                {status === 'loading' ? 'Subscribing...' : 'Subscribe'}
              </button>
            </form>
            {status === 'success' && (
              <p className="text-sm text-green-500">Thank you for subscribing!</p>
            )}
            {status === 'error' && (
              <p className="text-sm text-red-500">An error occurred. Please try again.</p>
            )}
          </div>
        </div>
      </div>
    </section> */}
  )
}

export default function Component() {
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id)
    element?.scrollIntoView({ behavior: 'smooth' })
  }

  const carouselImages: string[] = [
    'https://thinkii-website.s3.eu-north-1.amazonaws.com/tg_image_2772043108.png',
    'https://thinkii-website.s3.eu-north-1.amazonaws.com/tg_image_3002135958.png',
    'https://thinkii-website.s3.eu-north-1.amazonaws.com/tg_image_3374473762.png'
  ]

  const surveyResponses = [
    {
      quote: "An app that helps with reminders for things outside of my daily routine would be incredibly helpful.",
      respondent: "Survey respondent, Age 25-34, Female"
    },
    {
      quote: "For me, the app should be easy to use and navigate, with minimal loading times and intuitive design.",
      respondent: "Survey respondent, Age 35-44, Female"
    },
    {
      quote: "Integration with calendar and daily updates would help keep my days organized and manageable.",
      respondent: "Survey respondent, Age 35-44, Female"
    },
    {
      quote: "Sometimes I just need something that categorizes tasks but keeps them easy to find without overloading me with features.",
      respondent: "Survey respondent, Age 25-34, Female"
    },
    {
      quote: "Reminders, timer, task sharing, would be helpful to manage everything in one place.",
      respondent: "Survey respondent, Age 25-34, Female"
    },
    {
      quote: "For it to find time in the day to squeeze in tasks and remind me about them.",
      respondent: "Survey respondent, Age 35-44, Female"
    }
  ]

  return (
    <div className="flex flex-col min-h-screen bg-[#1C2826] text-[#DAEFB3] scroll-smooth font-['Montserrat']">
      <header className="fixed top-0 w-full px-4 lg:px-6 h-14 flex items-center border-b border-[#EA9E8D]/20 bg-[#1C2826]/95 backdrop-blur-sm z-50">
        <button 
          onClick={() => scrollToSection('top')} 
          className="flex items-center justify-center"
        >
          <Logo className="h-8" />
        </button>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <button onClick={() => scrollToSection('features')} className="text-sm font-medium hover:text-[#EA9E8D] transition-colors">
            Features
          </button>
          <button onClick={() => scrollToSection('about')} className="text-sm font-medium hover:text-[#EA9E8D] transition-colors">
            About
          </button>
         {/*} <button onClick={() => scrollToSection('contact')} className="text-sm font-medium hover:text-[#EA9E8D] transition-colors">
            Contact
          </button> */}
        </nav>
      </header>
      <main className="flex-1 pt-14">
        <section id="top" className="min-h-[90vh] flex items-center justify-center relative">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none text-[#F3F8F1]">
                  Designed for Women, Built for Balance
                </h1>
                <p className="mx-auto max-w-[700px] text-[#DAEFB3] md:text-xl">
                  Thinkii is an all-in-one, life-balancing app that integrates task management, self-care, reminders, and note-taking to help women stay organized, focused, and balanced.
                </p>
              </div>
              <div className="space-y-4">
               {/* <button className="inline-flex h-9 items-center justify-center px-6 rounded-md bg-[#D64550] text-[#F3F8F1] hover:bg-[#D64550]/90 transition-colors">
                  Join Waitlist
                  <ArrowRight className="ml-2 h-4 w-4" />
                </button> */}
              </div>
            </div>
          </div>
          <button 
            onClick={() => scrollToSection('features')} 
            className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-[#EA9E8D] animate-bounce"
            aria-label="Scroll to features"
          >
            <ChevronDown className="h-8 w-8" />
          </button>
        </section>

        <section id="features" className="py-16 md:py-24 bg-[#1C2826]/50">
          <div className="container px-4 md:px-6">
            <div className="grid gap-8 px-10 md:gap-12">
              <div className="space-y-4">
                <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight text-[#F3F8F1]">Features</h2>
                <p className="max-w-[800px] text-[#DAEFB3] md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  Designed as a "second brain," Thinkii simplifies daily life by reducing the mental load of managing multiple responsibilities, making it easier to achieve goals and enjoy life's moments. Whether it's work, home, personal growth, or social life, Thinkii enables users to stay on top of it all while prioritizing self-care and overall well-being.
                </p>
              </div>
              <div className="grid gap-8 md:grid-cols-2">
                <div className="rounded-lg border border-[#EA9E8D]/20 bg-[#1C2826] p-6">
                  <Layers className="h-12 w-12 mb-4 text-[#D64550]" />
                  <h3 className="font-bold mb-2 text-[#F3F8F1] text-xl">Life Balance Across Areas</h3>
                  <p className="text-sm text-[#DAEFB3]">
                    Effortlessly organize your tasks into 'buckets' like Self-Care, Home & Life, Work, Growth, and Social, covering all areas of life. See and manage everything in one place, giving you a clear, balanced approach to handling daily responsibilities.
                  </p>
                </div>
                <div className="rounded-lg border border-[#EA9E8D]/20 bg-[#1C2826] p-6">
                  <Clock className="h-12 w-12 mb-4 text-[#D64550]" />
                  <h3 className="font-bold mb-2 text-[#F3F8F1] text-xl">Time Balance and Prioritization</h3>
                  <p className="text-sm text-[#DAEFB3]">
                    Plan your day with ease using categories like Top for Today, Good for Myself, and Upcoming Tasks, helping you prioritize without feeling overwhelmed. Limit your top tasks each day for realistic goals, manageable expectations, and reduced stress.
                  </p>
                </div>
                <div className="rounded-lg border border-[#EA9E8D]/20 bg-[#1C2826] p-6">
                  <Calendar className="h-12 w-12 mb-4 text-[#D64550]" />
                  <h3 className="font-bold mb-2 text-[#F3F8F1] text-xl">Integrated Calendar and Task Management</h3>
                  <p className="text-sm text-[#DAEFB3]">
                    Stay in sync with Google Calendar integration and a customizable 'Bucket for Whatever' for both structured and flexible tasks. Enjoy weekly planning, timely reminders, a running task list, and a quick-add feature for those spontaneous to-dos.
                  </p>
                </div>
                <div className="rounded-lg border border-[#EA9E8D]/20 bg-[#1C2826] p-6">
                  <Brain className="h-12 w-12 mb-4 text-[#D64550]" />
                  <h3 className="font-bold mb-2 text-[#F3F8F1] text-xl">Smart Reminders and AI-Powered Suggestions</h3>
                  <p className="text-sm text-[#DAEFB3]">
                    Thinkii's smart reminders keep you on top of important tasks, while future AI integration can provide personalized recommendations and task suggestions, making it effortless to maintain a balanced and fulfilling lifestyle.
                  </p>
                </div>
              </div>
              <div className="mt-12">
                <Carousel images={carouselImages} />
              </div>
            </div>
          </div>
        </section>

        <section id="feedback" className="py-16 md:py-24 bg-[#1C2826]/50">
          <div className="container px-4 md:px-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div>
                <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight text-[#F3F8F1] mb-8">Who We Build For</h2>
                <p className="text-[#DAEFB3] md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed mb-8">
                  Thinkii is designed for busy, ambitious women who manage multiple roles—professionals, homemakers, caregivers, students, or entrepreneurs. These women seek a more balanced life, looking for tools that support productivity, self-care, and personal growth. Thinkii is particularly valuable for those who appreciate aesthetically pleasing, intuitive technology that reduces their mental load and empowers them to live a more fulfilling life.
                </p>
              </div>
              <div className="h-full w-full">
                <img
                  src="https://thinkii-website.s3.eu-north-1.amazonaws.com/priscilla-du-preez-nF8xhLMmg0c-unsplash.jpg"
                  alt="Woman using Thinkii app"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            </div>
            <div className="mt-12 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {surveyResponses.map((response, index) => (
                <div key={index} className="bg-[#1C2826] p-6 rounded-lg border border-[#EA9E8D]/20">
                  <p className="text-sm text-[#DAEFB3] italic">"{response.quote}"</p>
                  <p className="text-xs text-[#EA9E8D] mt-2">- {response.respondent}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="about" className="py-16 md:py-24">
          <div className="container px-4 md:px-6">
            <div className="grid gap-8 px-10 md:gap-12">
              <div className="space-y-4">
                <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight text-[#F3F8F1]">Our Mission and Values</h2>
                <p className="max-w-[800px] text-[#DAEFB3] md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  Our mission is to empower women to live more balanced, happy, and fulfilling lives by providing an intuitive, aesthetically pleasing app that reduces mental load through automated organization, planning, and self-care reminders. Our goal is to make every day more manageable and enjoyable.
                </p>
              </div>
              <div className="grid gap-8 md:grid-cols-3">
                {["Joyful Empowerment", "Creativity", "Integrity"].map((value, index) => (
                  <div key={index} className="space-y-4 rounded-lg border border-[#EA9E8D]/20 bg-[#1C2826] p-6">
                    <h3 className="text-xl font-bold text-[#F3F8F1]">{value}</h3>
                    <p className="text-sm text-[#DAEFB3]">
                {index === 0 && "We promote finding enjoyment and fulfillment in the pursuit of goals, encouraging individuals to prioritize activities that bring them happiness and meaning."}
                      {index === 1 && "We are committed to understanding and meeting the needs of our users, designing our brain assistant application to be intuitive, user-friendly, and aesthetically pleasing."}
                      {index === 2 && "We uphold the highest standards of honesty, ethics, and accountability, fostering trust and credibility while guaranteeing personal data protection for all."}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section id="story" className="py-16 md:py-24 bg-[#1C2826]/50">
          <div className="container px-4 md:px-6">
            <div className="grid gap-8 px-10 md:gap-12">
              <div className="space-y-4">
                <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight text-[#F3F8F1]">Our Story</h2>
                <p className="max-w-[800px] text-[#DAEFB3] md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  We created Thinkii after realizing just how difficult it is to stay on top of everything in daily life—from planning big projects to managing small details, like when to wash our hair. Both of us, despite being managers and highly organized, were juggling multiple to-do lists, screenshotting anything and everything, and even sending texts to ourselves just to keep track of things. But we often found ourselves unable to locate the notes and reminders we needed when we needed them.
                </p>
                <p className="max-w-[800px] text-[#DAEFB3] md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  Thinkii isn't just another tool; it's a second brain, helping women lighten the mental load, organize effortlessly, and find time for what truly matters. Join us in revolutionizing daily organization and making life more balanced and fulfilling.
                </p>
              </div>
            </div>
          </div>
        </section>

       {/* <Contact /> */}

        </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t border-[#EA9E8D]/20">
        <p className="text-xs text-[#DAEFB3]">© 2024 Thinkii. All rights reserved.</p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a href="mailto:thinkiidevelopment@gmail.com" className="text-xs text-[#DAEFB3] hover:text-[#EA9E8D] transition-colors">
            Contact Founders
          </a>
          <a 
            href="https://www.instagram.com/thinkiivibes/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-[#DAEFB3] hover:text-[#EA9E8D] transition-colors"
          >
            <Instagram className="h-4 w-4" />
            <span className="sr-only">Follow us on Instagram</span>
          </a>
          <a 
            href="https://www.tiktok.com/@thinkiivibes" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-[#DAEFB3] hover:text-[#EA9E8D] transition-colors"
          >
            <MessageSquare className="h-4 w-4" />
            <span className="sr-only">Follow us on TikTok</span>
          </a>
        </nav>
      </footer>
    </div>
  )
}