export function Logo({ className }: { className?: string }) {
  return (
    <svg 
      width="200" 
      height="200" 
      viewBox="0 0 400 100" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <text 
        x="10" 
        y="80" 
        className="text-[80px] font-bold"
        style={{ fontFamily: 'Montserrat' }}
      >
        <tspan fill="#DAEFB3">Think</tspan>
        <tspan fill="#EA9E8D">ii</tspan>
      </text>
    </svg>
  )
}